
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Message } from 'element-ui';

@Component
export default class FileUpload extends Vue {

    @Prop({default: ()=>[]}) fileList!: any[];   // 父组件传递的文件列表
    @Prop({ default: false }) autoUpload!: boolean;  // 是否自动上传
    // fileList: any[] = [];          // 存储已选文件的列表
    uploading: boolean = false;    // 是否正在上传
    uploadProgress: number = 0;    // 上传进度

    
    // 上传进度回调
    handleProgress(event: ProgressEvent, file: any, fileList: any[]) {
        const progress = Math.round((event.loaded / event.total) * 100);
        this.uploadProgress = progress;
    }

    // 文件上传前的验证
    beforeUpload(file: File) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2MB = file.size / 1024 / 1024 <= 2;
        if (!isJPG) {
            Message.error('上传图片只能是 JPG/PNG 格式!');
        }
        if (!isLt2MB) {
            Message.error('上传图片大小不能超过 2000KB!');
        }
        return isJPG && isLt2MB;
    }

    // 提交上传
    submitUpload() {
        const upload = this.$refs.upload as any; // 获取上传组件的引用
        // 开始上传
        this.uploading = true;
        upload.submit();  // 手动触发上传
    }

    // 上传完成回调
    handleSuccess(response: any, file: any, fileList: any[]) {
        Message.success('文件上传成功!');
        this.$emit('update:fileList', file);  // 上传成功后将文件列表传递给父组件
    }

    // 上传失败回调
    handleError(error: any, file: any, fileList: any[]) {
        Message.error('文件上传失败!');
        this.uploading = false;
    }
    // 文件移除回调
    handleRemove(file: any, fileList: any) {
        this.$emit('remove:fileList', file);  // 移除文件后将文件列表传递给父组件
    }

    get uploadURL(){
        return `${process.env.VUE_APP_API_URL}/upload`
    }
}
